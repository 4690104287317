import React from "react"
import { Link } from "gatsby"
import Container from "../components/container";
import Layout from "../components/layout"
import SEO from "../components/seo"
import {SectionA} from "../components/styles";

const NewPage = () => (
  <Layout>
    <SectionA>
      <Container>
        <SEO title="Strona wygenerowany" />
        <h1>Strona generowana automatycznie</h1>
        <Link to="/">Powrót do strony głównej</Link>
      </Container>
    </SectionA>
  </Layout>
)

export default NewPage
